import React from "react";
import { graphql } from 'gatsby';
import TemplateWrapper from "../../components/template-wrapper";

export default function techPrivacy ({ data }) {
  let { nodes: posts } = data.allMarkdownRemark;
  console.log("Posts length: " + posts.length);
  const accumulation = "";

  return (
    <div className="palette--ultra-site" style={{ textAlign: "center" }}> 
    <TemplateWrapper headerImages={data.allFile.nodes} accumulation = {accumulation} category={"ultrarunning"} >   
    {posts
        .filter(post => post.frontmatter.title.length > 0)
        .map( ( post ) => {
          return (
            <div className="blog-post" key={post.id}>
              <h2 dangerouslySetInnerHTML={{__html: post.frontmatter.title}} />
              <div dangerouslySetInnerHTML={{__html: post.html}} />
            </div>
          );
        })}
      <div style={{paddingBottom: "20rem"}}/>           
    </TemplateWrapper>
    </div>
  );
};

export const pageQuery = graphql`
  query ultraPrivacyQuery {
    allMarkdownRemark(
      filter: { frontmatter: { title: {regex: "/Privacy/"}, categories: { eq: "info" } } },
      sort: { order: DESC, fields: [frontmatter___date] }) {
      nodes {
        id
        html
        fields {
          link
        }
        frontmatter {
          date
          title
          author
          categories
          tags
        }
      }
    }
    allFile(filter: { sourceInstanceName: { eq: "images" }, 
    name: { regex: "/ultrarunning/" } }) 
    {       
      nodes {
        id
        relativePath
        extension
        name
        childImageSharp {
          gatsbyImageData( 
            layout: FULL_WIDTH, 
            placeholder: BLURRED
          )  
        }
      }
    }   
}
`;

